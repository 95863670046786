<template>
  <div class="code-wrap">
    <b-form-input
      ref="codeInput"
      :value="code"
      class="input"
      maxlength="6"
      type="number"
      @input="handleInput"
    />
    <b-row>
      <b-col
        v-for="(n,v) in 6"
        :key="v"
        class="col"
        md="2"
        xl="2"
      >
        <span
          :class="[v === codeArr.length ? 'breathe code-box active-box' : 'code-box']"
        >
          {{ codeArr[v] }}
          <span :class="[v === codeArr.length ? 'breathe line' : '']" />
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BFormInput,
  },
  data() {
    return {
      code: '',
    }
  },
  computed: {
    codeArr({ code }) {
      return code.split('')
    },
  },
  watch: {
    code(val) {
      if (val.length === 6) {
        this.$emit('codeSix')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.codeInput.focus()
    })
  },
  methods: {
    handleInput(e) {
      this.code = e
    },
  },
}
</script>

<style lang="scss" scoped>

  @keyframes breathe {
    0% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }

.breathe {
  animation: breathe 2s infinite ease;
}
.col{
  display: flex;
  justify-content: center;
}
.code-wrap{
  position: relative;
}
.input{
  border: none;
  background: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 3;
}
.code-box{
  border-bottom: 2px solid #666;
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 50px;
  position: relative;
}
.line{
  display: block;
  width: 2px;
  height: 60px;
  background: #FFAF2A;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  margin-top: -30px;
}
</style>
